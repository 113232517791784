<template>
    <div>
        <main>
            <div class="backgroundText">
                Welkom - Mirësevini - እንኳን ደህና መጡ - أهلا بك - Բարի գալուստ - Xoş gəlmisiniz - স্বাগতম - Dobrodošli - Добре дошли - Bienvenue - Benvingut - 欢迎 - 歡迎 - Dobrodošli - Vítejte - Velkommen - خوش آمدید - Welkom - Welcome - Tere tulemast - Tervetuloa - Bienvenue - სტუმარს - Willkommen - Καλώς ήρθατε - આપનું સ્વાગત છે - Akeyi - Barka da zuwa - ברוך הבא - वेलकम - Üdvözöljük - Velkomin - Selamat Datang - Fáilte - Benvenuto - ようこそ - ಸ್ವಾಗತ - қош келдіңіз - 어서 오십시오 - Laipni lūgti - Sveiki - Добредојдовте - Selamat datang - സ്വാഗതം - Merħba - आपले स्वागत आहे - Bienvenida - тавтай морилно уу - Velkommen - ښه راغلاست - Witamy - خوش آمدید - Bem-vindo - Bem-vindo - ਸੁਆਗਤ ਹੈ - Bine ai venit - Добро пожаловать - Dobrodošli - සාදරයෙන් පිළිගනිමු - Vitajte - Dobrodošli - Ku soo dhawow - Bienvenida - Karibu - Välkommen - Maligayang pagdating - நல்வரவு - స్వాగతం - ยินดีต้อนรับ - Hoşgeldiniz - Ласкаво просимо - خوش آمدید - Xush kelibsiz - Chào mừng - Croeso
            </div>
            <div class="row rowTest">
                <div class="col column">
                    <div class="row">
                        <Dropdown class="item" v-model="fromLanguage" :options="languages" optionLabel="languageName" placeholder="From Language" :filter="true"/>
                    </div>
                    <div class="row">
                        <Textarea class="item" v-model="value" :autoResize="true" rows="6" style="height: 30vh;" :maxlength="maxCharacters" :oninput="updateCharacterCount(value)" :auto-resize="false" />
                        <div>
                            <span id="characterCount">0</span>
                            <span id="maxCharacterCount"> / {{ maxCharacters }}</span>
                        </div>
                    </div>
                </div>
                <div class="col column">
                    <div class="row">
                        <Dropdown class="item" v-model="toLanguage" :options="languages.filter(l => l.languageCode != 'auto')" optionLabel="languageName" placeholder="To Language" :filter="true" />
                    </div>
                    <div class="row">
                        <Textarea class="item" v-model="translation" :autoResize="true" rows="6" style="height: 30vh;" :auto-resize="false" />
                    </div>
                </div>
            </div>
            <Button label="Translate" @click="getTranslation()"/>
        </main>
       <!-- signout button and tooltip -->
        <button class="signoutButton" @click="signOut">
            <svg class="signoutSvg">
                <path fill="black" d="M23.93,25v3h-16V4h16V7h2V3a1,1,0,0,0-1-1h-18a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1V25Z"></path>
                <line class="signoutLine" x1="28.92" x2="24.92" y1="16" y2="20"/>
                <line class="signoutLine" x1="28.92" x2="24.92" y1="16" y2="12"/>
                <line class="signoutLine" x1="14" x2="28" y1="16" y2="16"/>
            </svg>
        </button>

    </div>
</template>


<script>
export default {
    inject: ['auth'],
    name: 'Translate',
    components: {},
    data() {
        return {
            value: null,
            translation: null,
            fromLanguage: null,
            toLanguage: null,
            languages: [],
            maxCharacters: 500,
        }
    },
    async mounted() {
        try {
            var languagesResponse = await this.$translationApi.translation.get()
            this.languages = languagesResponse.data
        } catch (ex) {
            console.log("Failed getting languages:\n", ex)
        }
    },
    methods: {
        async getTranslation(){
            try {
                var translationPackage = { "FromLanguage": this.fromLanguage.languageCode, "Text": this.value, "ToLanguage": this.toLanguage.languageCode,};
                console.log(translationPackage)
                await this.$translationApi.translation.translate(translationPackage).then(response => this.translation = response.data);
            } catch (ex) {
                console.log("Translation Failed:\n", ex)
            }
        },

        updateCharacterCount(input) {
            if (input == null) {
                return
            }
            var characterCount = document.getElementById("characterCount")
            characterCount.innerHTML = input.length
        },

        signOut() {
            this.auth.signOut()
        },

    },
}
</script>

<style>

    html {
        height: 100%;
    }

    body {
        margin: 0px;
        background-image: url('https://oss.unifydemosuite.com/lobby2/Backgrounds/2.png');
        background-size: cover;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .backgroundText {
        position: absolute;
        bottom: 0;
        font-size: max(2.4vw, 2vh);
        -webkit-mask-image:-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
        z-index: -1;
        color: #4A4E69;
    }

    .rowTest {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10vh 0 1vh 0;
    }

    .column {
        width: max(49vw, 300px);
        padding: 0px 0px 10px 0px;
    }

    textarea {
        resize: none;
    }

    .item {
        width: max(45vw, 290px);
    }

    /* signout button */
    .signoutButton, .homeButton {
        border: none;
        border-radius: 0.5rem;
        background-color: white;
        position: absolute;
        top: 10px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        opacity: 0.2;
    }

    .signoutButton{
        right:10px;
    }

    .homeButton{
        left:10px;
    }

    .signoutButton:hover, .homeButton:hover {
        opacity: 1;
        box-shadow: rgba(46, 43, 43, 0.65) 1px 2px 5px 0px;
    }

    .signoutButton:hover + .signoutTooltip, .homeButton:hover + .homeButtonTooltip {
        opacity: 1;
    }

    .signoutSvg {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        transform: translate(6%, 17%);
    }

    .signoutLine {
        fill: none;
        stroke: black;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
    }

    .homeButtonTooltip {
        position: absolute;
        top: 20px;
        width: 100px;
        opacity: 0;
        text-align: center;
        line-height: 200%;
        font-family: 'Raleway', sans-serif;
        color: white;
        text-shadow: 0px 0px 5px #000;
    }
</style>
