export default class apiRoutes {
    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance
    }

    get() {
        return this.axiosInstance.get('translate')
    }

    translate(data){
        return this.axiosInstance.post('translate/', data)
    }
}