import { createRouter, createWebHistory } from 'vue-router'

import Translate from '@/views/Translate'

const routes = [{ path: '/', name: 'translate', component: Translate, meta: { requiresAuth: true } }]


const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
