import axios from 'axios'
import apiRoutes from './apiRoutes'

const axiosInstance = axios.create()
const translation = new apiRoutes(axiosInstance)

function configure(apiServer) {
    axiosInstance.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '/api' : ':' + apiServer.port) + '/'
}

function install(app) {
    app.config.globalProperties.$translationApi = this
}

function useAuth(auth) {
    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })
}

const apiService = {
    configure,
    install,
    useAuth,
    translation
}

export default apiService
