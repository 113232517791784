import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createAuth } from '@/plugins/oidc'
import translationApi from './services/API'
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons


const app = createApp(App)

import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

fetch(process.env.BASE_URL + 'config.json')
    .then((response) => response.json())
    .then((config) => {
        const auth = createAuth(config.oidc)
        auth.useRouter(router)
        app.use(auth)

        app.use(router)

        translationApi.configure(config.apiServer)
        translationApi.useAuth(auth)
        app.use(translationApi)

        app.use(PrimeVue);

        app.component('Textarea', Textarea);
        app.component('Button', Button);
        app.component('Dropdown', Dropdown);

        app.mount('#app')
    })